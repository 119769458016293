#instafeed {
	a.instablock {
		img {
			padding: 5px;
		}
	}
}

#tweets-container {
	ul {
		padding: 0;
		list-style: none;
		
		li {
			position: relative;
			margin-bottom: 5px;
			background: $gris-clair;
			padding: 15px;
			
			.tweet {
				
				&:before {
					content: "";
					font-family: "FontAwesome";
					margin-right: 5px;
					//color: rgba(0,0,0,0);
				}
			}
			
			 .interact {
				 display: none;
			 }
			 
			 .timePosted {
				 position: absolute;
				 bottom: 0;
				 right: 15px;
				 font-size: 12px;
			 }
		}
	}
}

.section-reseaux {
	.compte-container {
		
		margin-bottom: 15px;
		
		.logo-reseaux {
			display: inline-block;
			max-width: 50px;
			margin-left: 15px;
		}
		.compte-name {
			display: inline-block;
		}
	}
}