.btn {
	border-width: 2px;
}

.btn.btn-primary {
	&:hover {
		background: $vert;
		color: #fff;
	}
}

.btn.btn-bleu {
	border-color: $bleu;
	color: $bleu;
	background: rgba(255,255,255,0.8);
	
	&:hover {
		background: $bleu;
		color: #fff;
	}
}