body.home {
	h1 {
		color: $bleu;
	}
}

.offset-container {
	margin-top: -55px;
	z-index: 1;
}


.container-offre {
	>.vc_column-inner {
		> .wpb_wrapper {
			background: #fff;
			border-radius: 20px;

			@include box-ombre;
			
			.title {
				margin: 0;
				padding: 15px 0;
				color: $vert;
				
				h3 {
					color: $vert;
				}
			}
			
			.texte {
				padding: 0 30px;
			}
			
			.bloc-bouton {
				padding-bottom: 15px;
			}
		}
	}
	
	@include transform(translateY(15px));
	
	&.container-offre-small {
		@include transform(scale(0.9));
	}
}

.section-ronds {
	.v-center {
		.h2 {
			margin-top: 0;
		}
	}
}

.section-news {
	
	
	> .row {
		padding: 90px 0;
	}
	
	article {
		background: #fff;
		border-radius: 20px;
		height: calc(100% - 30px);
		
		header {
			background: url('../images/bg-header-news.jpg') center center;
			background-size: cover;
			border-top-right-radius: 20px;
			border-top-left-radius: 20px;
			position: relative;
			padding: 15px 0 15px 100px;
			
			.date {
				position: absolute;
				left: 25px;
				top: 0;
				background: $vert;
				
				text-align: center;
				font-size: 30px;
				color: #fff;
				text-transform: uppercase;

				.wrapper {
					position: relative;
					padding: 5px 5px 15px 5px;
					
					&:before {
						content: " ";
						display: block;
						width: 100%;
						height: 30px;
						background-image: url('../images/ruban-date.png');
						//background-position: center top;
						position: absolute;
						top: 100%;
						left: 0;
						right: 0;
						background-size: 100% 100%;
					}
				}
				
			}

			.categorie {
				color: #fff;
				text-transform: uppercase;
				font-size: 13px;
				margin-bottom: 0;
			}
			
			.titre {
				color: #fff;
				text-transform: uppercase;
				font-size: 20px;
				margin-bottom: 0;
			}
		}
		
		.content {
			padding: 15px 15px 15px 100px;
			p {
				//font-size: 12px;
			}
		}
	}
	
	.container-newsletter {
		background: $vert;
		border-radius: 20px;

		header {
			img {
				border-top-right-radius: 20px;
				border-top-left-radius: 20px;
			}
		}
		
		.titre {
			color: #fff;
			text-transform: uppercase;
			text-align: center;
		}
		
		.content {
			padding: 15px 30px;
			color: #fff;
			p.texte {
				color: #fff;
				font-size: 12px;
			}
		}
	}
	
}

.section-newsletter {
	position: relative;
	
	margin-top: 30px;
	
	> .row {
		
		
		&:before {
			content: url('../images/post-banner.png');
			position: absolute;
			top: -100px;
			left: -1250px;
			margin-left: 50%;
		}
	}
}