
		
#home-slider {		
	
	z-index: 0;
	
	.home-mask-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 110px;
		background-image: url('../images/mask-slider-bottom.png');
		background-position: center bottom;
		background-repeat: no-repeat;
		z-index: 1;
	}

	.carousel-indicators {
	  position: static;
	  bottom: 0 none;
	  left: auto;
	  z-index: 15;
	  width: 100%;
	  margin-left: 0;
	  padding-left: 0;
	  list-style: none;
	  text-align: left;
	  margin-bottom: 0;
	
	  li {
		float: left;
	    display: block;
	    width:  25%;
	    height: 100px;
	    margin: 0;
	    text-indent: inherit;
	    border: 0 none;
	    border-bottom: 4px solid #49545f;
	    border-radius: 0;
	    cursor: pointer;
		//background: #e8e7e7 left center no-repeat url('../images/elements/puce-slide-home.png');
		padding-left: 40px;
		position: relative;
		  transition: 0.5s all;
		  	-ms-transition: 0.5s all;
		  	-moz-transition: 0.5s all;
		  	-webkit-transition: 0.5s all;
		  	-o-transition: 0.5s all;
		  	
		border-right: 1px solid #90969d;

		@media(max-width: $screen-lg) {
			float: left;
			width: 50%;
		}

		@media(max-width: $screen-sm) {
			width: 100%;
			float: left;
		}


		&:last-child {
			@media(min-width:$screen-lg) {
				//border: 0 none;
			}
			border-right: 0 none;
		}
		
		p {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #515354;
			left: 0;
			right: 0;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			
			span {
				color: $rouge;
				font-weight: bold;
			}
		}
	  }
	  .active {
	    margin: 0;
	    width:  25%;
	    height: 100px;
	    //background-color: #8c8a8a;
	    border-bottom-color: $rouge;

		@media(max-width: $screen-lg) {
			width: 50%;
			float: left;
		}

		@media(max-width: $screen-sm) {
			width: 100%;
			float: left;
		}

	    p {
		    //color: #fff;
		    color: $rouge;
	    }
	  }
	}	
		.item {
		//position: relative;
		background-size: cover;
		background-position: center center;
		
		.container-texte {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			
			.wrapper-rel {
				position: relative;
				height: 100%;
			}
			
			.container-cadre {
							
				position: absolute;
				//right:15px;
				//bottom: 40px;
				
				bottom: 130px;
				//top: 50%;
				left: 30px;
				right: 30px;
				@include transform(translate(0,-50%));
				
				@media(min-width: $screen-sm) {
					right: 0;
					left: 50%;
					//@include transform(translate(-50%,-50%));
					@include transform(translateX(-50%));
				}
				
				z-index: 10;

					a {
						text-decoration: none;
						
						&:hover {
							text-decoration: none;
						}
					}

				p.titre-slide {
					margin-bottom: 10px;
					

					span {
						color: #fff;
						padding: 5px;
						font-size: 22px;
						font-weight: 300;
						text-transform: uppercase;
						text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

						@media(min-width: $screen-sm) {
							font-size: 32px;
						}

					}
				}
				
				
				.texte-slide p {
					padding: 5px;
					//width: 400px;
					//background: #fff;
					//font-size: 14px;
					//font-weight: 300;
					text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
					color: #fff;
					line-height: 30px;

					@media(min-width: $screen-sm) {
						//font-size: 16px;
					}

					a {
						color: #fff;
					}
					
					&.titre {
						text-transform: uppercase;
						font-size: 24px;
						margin-bottom: 0;
						line-height: 34px;
						font-weight: 700;
						@media(min-width: $screen-sm) {
							font-size: 34px;
						}
					}
					&.sous-titre {
						font-size: 18px;
						margin-bottom: 15px;
						@media(min-width: $screen-sm) {
							font-size: 28px;
						}
					}
				}
			}
			
		}
	}
}


#testimonial-slider {
	
	background: left top no-repeat url(../images/quote-left.png);
	background-size: 100px;
	padding: 30px;
	
	.bg2 {
		background:  right bottom no-repeat url(../images/quote-right.png);
		background-size: 100px;
		padding-bottom: 30px;
	}
	
	 .carousel-indicators {
		 bottom: -20px;
		 
		 li {
			background: #ddd;
			
			&.active {
				background: $rouge;
			}
		}
		 
	 }
	 
	 .item {
		 .temoignage {
			 p {
				 text-align: center;
				 font-size: 16px;
				 line-height: 25px;
			 }
		 }
		 
		 p.auteur {
			 text-align: center;
			 text-transform: uppercase;
			 
			 span {
				 color: $rouge;
				 font-weight: 700;
			 }
		 }
	 }
}