body.fixed-header {
	padding-top: 200px;
}

body.fixed-header-slider {
	padding-top: 100px;
}

body > .wrap {
	overflow: hidden;
}

a, .btn {
	@include transition (all 0.3s);
}

a {
	//text-decoration: underline;
}

.btn {
	text-transform: uppercase;
}

.logo-partenaire img {
	width: 200px;
	
	@media(max-width: $screen-xs) {
		width: 150px;
	}
}

p, ul li, ol li {
	font-weight: 300;
}

h1,.h1 {
	margin-top: 0;
	.sous-titre {
		display: block;
		font-size: floor(($font-size-h1 * 0.5));
		font-weight: 700;
	}
}

h2,h2 {
	margin-top: 0;
	.sous-titre {
		display: block;
		font-size: floor(($font-size-h2 * 0.5));
		font-weight: 700;
	}
}

.h1 {
	font-size: $font-size-h1;
	font-weight: $headings-font-weight;
}
.h2 {
	font-size: $font-size-h2;
	font-weight: $headings-font-weight;
}
h3,.h3 {
	margin-top: 0;
	font-size: $font-size-h3;
	//font-weight: 700;
}
h4,.h4 {
	margin-top: 0;
	font-size: $font-size-h4;
	//font-weight: 700;
}
@media(min-width: $screen-sm) {
	.extra-padding-left > .vc_column-inner  {
		padding-left: 30px;
	}
	.extra-padding-right > .vc_column-inner  {
		padding-right: 30px;
	}
}

@media(max-width: $screen-xs) {
	h1,.h1 {
		font-size: floor(($font-size-h1 /1.3));
		.sous-titre {
			font-size: floor(($font-size-h1 /2.6));
		}
	}
	
	h2,.h2 {
		font-size: floor(($font-size-h2 /1.1));
	}
	h3,.h3 {
		font-size: floor(($font-size-h3 /1.1));
	}
	h4,.h4 {
		font-size: floor(($font-size-h4 /1.1));
	}
}

hr {
	width: 80px;
	background: $vert;
	color: $vert;
	height: 2px;
	margin: 15px 0;
}

.marge-top {
	padding-top: 35px;
}
.marge-bottom {
	padding-bottom: 35px;
}

.fond-vert {
	background-color: $vert;
}

.fond-gris {
	background: #f1f1f2;
}

.fond-violet {
	background-image: url('../images/bg-violet.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;
	
	@media(max-width: $screen-md) {
		background-image: none;
		background-color: #344782;
	}
}

.fond-triangles {
/*	background-image: url('../images/fond-triangles.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;
	*/
	
	position: relative;
	
	&:before {
		content: url('../images/fond-triangles.png');
		left: -500px;
		bottom: 0;
		position: absolute;
		z-index: -1;
	}
	
	@media(max-width: $screen-sm) {
		background-image: none;
		//background-color: #344782;
	}
}

.fond-news {
	background-image: url('../images/bg-news.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 100%;
	
}

.fond-violet-plein {
	background-image: url('../images/bg-violet-plein.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	
	@media(max-width: $screen-md) {
		background-image: none;
		background-color: #344782;
	}
}

.texte-blanc {
	color: #fff;
	p, ul li, ol li, h1, h2, h3, h4 {
		color: #fff;
	}
}

.texte-bleu {
	color: $bleu;
	p, ul li, ol li, h1, h2, h3, h4 {
		color: $bleu;
	}
}

.texte-vert {
	color: $vert;
	p, ul li, ol li, h1, h2, h3, h4 {
		color: $vert;
	}
}

.v-center-container {
	position: relative;
	
	.v-center {
		position: absolute;
		margin-bottom: 0;
		top: 50%;
		left: 0;
		right: 0;
		padding: 0 23%;
		@include transform(translateY(-50%));
	}
}

.text-sm {
	font-size: $font-size-small;
}

.bloc-texte {
	p, ol li, ul li {
		line-height: 2em;
	}
	
	ul {
		list-style: none;
		
		> li {
			
			padding-left: 15px;
			
			&:before {
				position: relative;
				content: "";
				left: -15px;
				font-family: "FontAwesome";
				color: $vert;
			}
			
		}
	}
}

ul.pager {
	> li {
		> a {
			&:hover {
				color: #fff;
			}
		}
	}
}