header.banner {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 5000;
	@include degrade-vert-bleu;
	
	@media(min-width: $grid-float-breakpoint) {
		background: transparent;
	
		&:before {
			content: url('../images/bg-header.png');
			display: block;
			position: absolute;
			left: 50%;
			margin-left: -1250px;
			//right: 0;
			top: 0;
		}
	}
	
	.top-line {
		position: relative;
	}
	
	.btn-espace-elus {
		position: absolute;
		top: 0;
		right: 0;
		background: $vert;
		color: #fff;
		padding: 5px 15px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		z-index: 10000;
		&:hover {
			text-decoration: none;
			background-color: darken($vert,10%);
		}
	}
	
	.navbar {
		margin-top: $navbar-margin-bottom;
	}

	.top-barre {
		background: $vert;
		color: #fff;
		
		.top-barre-container {
			position: relative;
			margin-bottom: 0;
			margin-top: 0;
			padding: 25px 0 5px 0;

			p {
				margin: 0;
				font-size: 13px;
				
				span {
					color: #000;
				}
			}
			
			.block-social {
				@media(min-width: $grid-float-breakpoint) {
					position: absolute;
					right: 0;
					bottom: 5px;
				}
				
				p {
					text-align: center;
				}
				
				a {
					i {
						font-size: 20px;
						color: #fff;
						padding-left: 5px;
					}
				}
			}
			
			.widget_nav_menu {
				@media(max-width: $grid-float-breakpoint) {
					display: none;
				}
				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					text-align: right;
					margin-right: 90px;
					
					> li {
						display: inline-block;
						margin: 0 5px;
						
						> a {
							color: #fff;
							text-decoration: none;
							text-transform: uppercase;
							font-size: 14px;
							font-weight: 700;
						}
					}
				}
			}
			
		}
		
	}
	
	.container-logo {
		position: relative;
		
		.base-line {
			position: absolute;
			top: 5px;
			left: 200px;
			color: #fff;
			font-size: $font-size-h1;
			text-transform: uppercase;
			
			@media(max-width: $screen-md) {
				display: none;
			}
		}
	}
	
	a.logo {
		//padding: 10px 0;
		//display: inline-block;
		//position: absolute;
		//left: 0;
		//top: 0;
		
		img {
			max-width: 150px;
		}
	}
	
	.container-menu-top {
		
		position: relative;
		
		.logo-mobile {
			display: inline-block;
			margin-left: 15px;
			
			img {
				max-width: 100px;
			}
			@media(min-width: $grid-float-breakpoint) {
				display: none;
				max-width: 150px;
			}
		}

		@media(min-width: $grid-float-breakpoint) {
			.dropdown:hover .dropdown-menu {
			    display: block;
			}
			.dropdown-menu {
			    margin-top: 0px;
			}
		}

		@media(max-width: $grid-float-breakpoint) {
			a.logo {
				display: none;
			}
		}

		#menu-primary {
			
			@media(min-width: $grid-float-breakpoint) {
				position: absolute;
				bottom: 60px;
				right: 0;
			}
			
			@media(min-width: $screen-md) {
				> li {
					> a {
						//font-size: 20px;
					}
				}
			}
			
			> li {
				
				@media(min-width: $grid-float-breakpoint) {
					&.mobile-only {
						display: none;
					}
					
					border-right: 1px solid #fff;
					
					&:last-child {
						border-right: 0 none;
					}
				}
				
				> a {
					//color: $text-color;
					text-transform: uppercase;
					cursor: pointer;
					font-size: 13px;
					padding-top: 0;
					padding-bottom: 5px;
					font-weight: 600;
					
					span.fa {
						color: #a4a8ae;
					}
					
					@media(max-width: $grid-float-breakpoint) {
						&.dropdown-toggle {
							&:before {
								font-family: "FontAwesome";
								content: "";
								margin-right: 5px;
								color: #fff;
							}
							span.fa {
								display: none;
							}	
						}
					}
				}
				
				&.active {
					> a {
						color: $vert;
						
						span.fa {
							color: $vert;
						}
						
						@media(max-width: $grid-float-breakpoint) {
							&:before {
								color: $vert;
							}
						}

					}
				}
			}
		}
	}

	button.navbar-toggle {
		
		//position: absolute;
		//top:20px;
		//right: 0;
		//margin: 0;
				margin-top: 30px;
		  //border: none;
		  //background: transparent !important;
		
		  &:hover {
		    //background: transparent !important;
		  }
		
		  .icon-bar {
		    width: 22px;
		    transition: all 0.2s;
		  }
		  .top-bar {
		    transform: rotate(45deg);
		    transform-origin: 10% 10%;
		    background-color: $vert;
		  }
		  .middle-bar {
		    opacity: 0;
		  }
		  .bottom-bar {
		    transform: rotate(-45deg);
		    transform-origin: 10% 90%;
		    background-color: $vert;
		  }

		  &.collapsed {
			  	.top-bar {
				 transform: rotate(0);
				 background-color: $navbar-default-toggle-icon-bar-bg;
				}
			  .middle-bar {
			    opacity: 1;
			    background-color: $navbar-default-toggle-icon-bar-bg;
			  }
			  .bottom-bar {
			    transform: rotate(0);
			    background-color: $navbar-default-toggle-icon-bar-bg;
			  }
			}
		}	

	
}

.header-page {
	position: relative;
	z-index: 0;
	
	.home-mask-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 110px;
		background-image: url('../images/mask-slider-bottom.png');
		background-position: center bottom;
		background-repeat: no-repeat;
		z-index: 1;
	}
	
	> .row {
		height: 400px;
		position: relative;
		background-position: center top;
		background-size: cover;
		
		.container-h1 {
				position: absolute;
				top: calc(50% - 50px);
				@include transform(translateY(-50%));
				left: 0;
				right: 0;
			h1 {
				color: #fff;
				text-align: center;
				margin: 0 auto;
				max-width: 420px;
			}
		}
		
	}
}