.compteur-chiffre {
	
	&.chiffre-vert {
		
		.chiffre {
			.chiffre-counter {
				background: #fff;
				border: 10px solid $vert;
				color: $bleu;
				font-weight: 600;
			}
		}
	}


	.chiffre {
		
		position: relative;
		
		.chiffre-counter {
			font-weight: 300;
			font-size: 26px;
			line-height: 140px;
			height: 155px;
			width: 155px;
			margin: 0 auto;
			color: #fff;
			border-radius: 150px;
			margin-bottom: 15px;
		}
		
		.legend {
			//position: absolute;
			color: #fff;
			font-weight: 700;
			font-size: 16px;
			bottom: 25px;
			@include transform(translateX(-50%));
			//display: none;
		}
		
		.legend-sub {
			color: #fff;
			font-weight: 700;
			font-size: 16px;
		}
		
		.currency {
			font-size: 20px;
		}
	}
}

