@mixin degrade-vert-bleu {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#66b2bf+0,3e538d+100 */
	background: #66b2bf; /* Old browsers */
	background: -moz-linear-gradient(left, #66b2bf 0%, #3e538d 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #66b2bf 0%,#3e538d 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #66b2bf 0%,#3e538d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66b2bf', endColorstr='#3e538d',GradientType=1 ); /* IE6-9 */
}

@mixin box-ombre {
	-webkit-box-shadow: 0px 15px 15px 0px rgba(0,0,0,0.15);
	-moz-box-shadow: 0px 15px 15px 0px rgba(0,0,0,0.15);
	box-shadow: 0px 15px 15px 0px rgba(0,0,0,0.15);
}