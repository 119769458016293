footer.content-info {
	background: #808285;
	padding: 30px 0;
	
	section {
		
		.titre {
			color: #fff;
			text-transform: uppercase;
			//font-weight: 700;
			//text-decoration: underline;
			font-size: 12px;
			
			a {
				color: #fff;
				text-decoration: none;
				&:hover {
					color: #fff;
					text-decoration: underline;
				}
			}
		}
		
		&.widget_text {
			p {
				color: #fff;
				font-size: 12px;
				
				a {
					//color: $vert;
				}
				
			}
			
			&.social-icones {
				a {
					color: #fff;
				}
				.fa {
					font-size: 20px;
					margin-right: 5px;
				}
			}
		}
		
		
		&.widget_nav_menu {
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				
				> li {
					margin-bottom: 3px;
					> a {
						color: #fff;
						padding-left: 15px;
						position: relative;
						display: block;
						font-size: 12px;
						
						&:before {
							color: $vert;
							font-family: "FontAwesome";
							content: "";
							left: 0;
							top: 0;
							position: absolute;
							font-size: 10px;
						}
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}