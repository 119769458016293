$border-radius-galerie: 20px;

.section-galerie {
	.owl-galerie {
		position: relative;
		
		.owl-nav {
			i {
				color: #fff;
				font-size: 20px;
			}
			
			.owl-prev {
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				left: -15px;
				
				i {
					color: #fff;
				}
			}
			
			.owl-next {
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				right: -15px;
				
			}

		}
		
		.owl-item {
			@include transform (scale(0.9));
			@include transition (all 1s);
			
			&.center {
				@include transform(scale(1));
				@include box-ombre;
			}
		}
		
		.item {
			border-radius: $border-radius-galerie;
			
			a {
				display: block;
				color: $text-color;
				text-decoration: none;
					&:hover {
						text-decoration: none;
					}
				
				img {
					border-top-left-radius: $border-radius-galerie;
					border-top-right-radius: $border-radius-galerie;
				}
				
				p {
					background: #fff;
					text-transform: uppercase;
					color: $text-color;
					border-bottom-left-radius: $border-radius-galerie;
					border-bottom-right-radius: $border-radius-galerie;
					min-height: 60px;
					margin-bottom: 0;
					padding: 5px 10px;
					text-align: center;
					font-size: 13px;
				}
			}
		}
	}
}


.newsletter-bottom-margin {
	.section-galerie {
		.marge-bottom {
			padding-bottom: 130px;
		}
	}
}



//CHOCOLAT

.chocolat-zoomable.chocolat-zoomed{
    cursor : zoom-out;
}
.chocolat-open{
    overflow: hidden;
}
.chocolat-overlay{
    height:100%;
    width:100%;
    position:fixed;
    left:0;
    top:0;
    z-index:10000;
    background-color:#fff;
    display: none;
    opacity: 0.8;
}
.chocolat-wrapper{
    height:100%;
    width:100%;
    position:fixed;
    display: none;
    left:0;
    top:0;
    z-index:16000;
    color: #fff;
}
.chocolat-zoomable .chocolat-img{
    cursor : zoom-in;
}
.chocolat-loader{
    height:32px;
    width:32px;
    position:absolute;
    left:50%;
    top:50% ;
    margin-left:-16px;
    margin-top:-16px;
    z-index:11000;
    background:url(../images/loader.gif);
    display:none;
}
.chocolat-content{
    position:fixed;
    width:0px;
    height:0px;
    left:50%;
    top:50%;
    z-index:14000;
    text-align: left;
}

.chocolat-content .chocolat-img{
    position:absolute;
    width:100%;
    height:100%;
}
.chocolat-wrapper .chocolat-left{
    position:absolute;
    left:0;
    width:50px;
    height:100px;
    top: 50%;
    margin-top: -50px;
    cursor:pointer;
    background:url(../images/left.png) 50% 50% no-repeat;
    z-index: 17000;
    display: none;

}
.chocolat-wrapper .chocolat-right{
    position:absolute;
    right:0;
    width:50px;
    height:100px;
    top: 50%;
    margin-top: -50px;
    cursor:pointer;
    background:url(../images/right.png)  50% 50% no-repeat;
    z-index: 17000;
    display: none;
}
.chocolat-wrapper .chocolat-right.active{
    display: block;
}
.chocolat-wrapper .chocolat-left.active{
    display: block;
}
.chocolat-wrapper .chocolat-top{
    position:absolute;
    top:0px;
    right: 0;
    left: 0;
    line-height:50px;
    height:50px;
    overflow: hidden;
    z-index: 17000;
    margin-bottom: 10px;
}
.chocolat-wrapper .chocolat-close{
    width:50px;
    height:50px;
    cursor:pointer;
    position: absolute;
    top: 0;
    right: 0;
    background:url(../images/close.png) 50% 50% no-repeat;
}
.chocolat-wrapper .chocolat-bottom{
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
    line-height:40px;
    height:40px;
    font-size:12px;
    z-index: 17000;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.2);
    text-align: right;
    margin-top: 10px;
}
.chocolat-wrapper .chocolat-set-title{
    display: inline-block;
    padding-right: 15px;
    line-height: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.chocolat-wrapper .chocolat-pagination{
    float: right;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}
.chocolat-wrapper .chocolat-fullscreen{
    width: 16px;
    height: 40px;
    background: url(../images/fullscreen.png) 50% 50% no-repeat;
    display: block;
    margin:auto;
    cursor: pointer;
    float: right;
}
.chocolat-wrapper .chocolat-description{
    display: inline-block;
    float: left;
}

/* no container mode*/
body.chocolat-open>.chocolat-overlay{
    z-index:15000;
}
body.chocolat-open>.chocolat-loader{
    z-index:15000;
}
body.chocolat-open>.chocolat-content{
    z-index:17000;
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-content,
.chocolat-in-container .chocolat-overlay{
    position: absolute;
}
.chocolat-in-container{
    position: relative;
}

/* uncomment to hide controls when zoomed-in*/
/*
.chocolat-zoomable .chocolat-top,
.chocolat-zoomable .chocolat-bottom,
.chocolat-zoomable .chocolat-right,
.chocolat-zoomable .chocolat-left {
    transition: opacity .3s ease, visibility 0s .3s;
   opacity: 1;
}

.chocolat-zoomable.chocolat-zoomed .chocolat-top,
.chocolat-zoomable.chocolat-zoomed .chocolat-bottom,
.chocolat-zoomable.chocolat-zoomed .chocolat-right,
.chocolat-zoomable.chocolat-zoomed .chocolat-left {
    visibility: hidden;
    opacity: 0;
}
*/
